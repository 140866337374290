@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600&family=Zen+Tokyo+Zoo&display=swap);
 html, body{
   padding: 0!important;
   margin: 0!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background: linear-gradient(#fffef6, rgb(186, 255, 174));*/
    background: linear-gradient(rgba(10, 10, 10, 0.91), rgba(0, 0, 0, 0.71)), repeating-linear-gradient(0, transparent, transparent 2px, rgba(0, 0, 0, 0.52) 2px, rgba(0, 0, 0, 0) 3px)
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

/*h1 {*/
/*  font-size: 45px;*/
/*}*/
/*h2 {*/
/*  margin-top: 1.5em;*/
/*}*/
/*p {*/
/*  font-size: 16px;*/
/*}*/
/*a {*/
/*  border-bottom: 1px solid white;*/
/*  color: #0076ff;*/
/*  cursor: pointer;*/
/*  text-decoration: none;*/
/*  transition: all 0.2s ease;*/
/*}*/
/*a:hover {*/
/*  border-bottom: 1px solid #0076ff;*/
/*}*/
/*code,*/
/*pre {*/
/*  color: #d400ff;*/
/*  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,*/
/*    Bitstream Vera Sans Mono, Courier New, monospace, serif;*/
/*  font-size: 0.92em;*/
/*}*/
/*code:before,*/
/*code:after {*/
/*  content: '\`';*/
/*}*/

/*.name{*/
/*  visibility: inherit;*/
/*  opacity: 1;*/
/*  transform: matrix(1, 0, 0, 1, 0, 0);*/
/*  font-size: 1.2rem;*/
/*  font-weight: 700;*/
/*  color: #682ae9;*/
/*}*/


/******************************************* Centre ******************************************************************/
.orga{
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-justify-content: center;
          justify-content: center;

}

/************************************************ bloc 1 **************************************************************/

.blocUn{
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #0a0a0a;
  background: linear-gradient(rgba(211, 211, 211, 0.85), rgba(181, 181, 181, 0.5));
  width: 100%;
  height: 80vh;
  border-bottom: #dbdbdb 1px solid;
}

.title{
  font-family: 'Indie Flower', cursive;
  font-weight: 800;
  font-size: 45px;
}

.title-bis{
  font-family: 'Indie Flower', cursive;
  font-weight: 600;
  font-size: 20px;
}

.dev{
  font-family: OCR A Std, monospace;
  font-weight: bold;
}

/********************************** Bloc Deux ***********************************************************************/
.blocDeux{
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  color: #4b4b4b;
  background: linear-gradient(rgba(181, 181, 181, 0.5), rgba(211, 211, 211, 0.85));
  width: 100%;
  height: 80vh;
  border-bottom: #dbdbdb 1px solid;
}
/***********************************Bloc Trois **************************************************************************/

.blocTrois {
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  color: #535353;
  background: linear-gradient(rgba(211, 211, 211, 0.85), rgba(181, 181, 181, 0.5));
  width: 100%;
  height: 80vh;
  border-bottom: #dbdbdb 1px solid;
}
/****************************************************************** HEADER ********************************************/
@media all {
  .header {
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    display: -webkit-flex;
    display: flex;
    height: 5rem;
    width: 100%; }
  .header-nav {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 3rem;
    margin: 0 !important; }
  ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    list-style: none;
    padding: 0 0 0 !important;
    margin: 0 !important; }
  li {
    margin-left: 1rem;
    list-style: none; }
  a {
    display: inline-block;
    -webkit-align-items: center;
            align-items: center;
    color: #b5b5b5;
    transition: color 400ms ease-out;
    list-style: none;
    line-height: 1; }
  a:hover {
    color: #918c90; }
  svg {
    fill: #dbdbdb; }
  a.router-link-exact-active {
    opacity: .5;
    cursor: default; }
  svg {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    fill: #682ae9;
    position: relative;
    top: 2px;
    transition: fill 400ms ease-out; }
  /**********************************************************************************************************************/
  .name {
    margin-left: 5px;
    font-family: 'Dancing Script', cursive;
    position: relative;
    color: #b5b5b5;
    font-size: 2.5em;
    letter-spacing: .2em;
    animation: name-skew 1s infinite linear alternate-reverse; }
    .name::before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      left: 2px;
      text-shadow: -3px 0 #ff3520, 2px 2px #101141;
      clip: rect(44px, 800px, 56px, 0);
      animation: name-anim 7s infinite linear alternate-reverse; }
    .name::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      left: -2px;
      text-shadow: -2px 0 #0f1ba0, 2px 2px #ffe823;
      animation: name-anim2 4s infinite linear alternate-reverse; }
  @-webkit-keyframes name-anim {
    0% {
      clip: rect(100px, 9999px, 5px, 0);
      -webkit-transform: skew(0.79deg);
              transform: skew(0.79deg); }
    3.33333% {
      clip: rect(67px, 9999px, 94px, 0);
      -webkit-transform: skew(0.32deg);
              transform: skew(0.32deg); }
    6.66667% {
      clip: rect(47px, 9999px, 71px, 0);
      -webkit-transform: skew(0.33deg);
              transform: skew(0.33deg); }
    10% {
      clip: rect(62px, 9999px, 66px, 0);
      -webkit-transform: skew(0.17deg);
              transform: skew(0.17deg); }
    13.33333% {
      clip: rect(8px, 9999px, 41px, 0);
      -webkit-transform: skew(0.4deg);
              transform: skew(0.4deg); }
    16.66667% {
      clip: rect(48px, 9999px, 69px, 0);
      -webkit-transform: skew(0.87deg);
              transform: skew(0.87deg); }
    20% {
      clip: rect(40px, 9999px, 6px, 0);
      -webkit-transform: skew(0.58deg);
              transform: skew(0.58deg); }
    23.33333% {
      clip: rect(60px, 9999px, 9px, 0);
      -webkit-transform: skew(0.22deg);
              transform: skew(0.22deg); }
    26.66667% {
      clip: rect(70px, 9999px, 53px, 0);
      -webkit-transform: skew(0.79deg);
              transform: skew(0.79deg); }
    30% {
      clip: rect(41px, 9999px, 61px, 0);
      -webkit-transform: skew(0.32deg);
              transform: skew(0.32deg); }
    33.33333% {
      clip: rect(50px, 9999px, 54px, 0);
      -webkit-transform: skew(0.47deg);
              transform: skew(0.47deg); }
    36.66667% {
      clip: rect(25px, 9999px, 16px, 0);
      -webkit-transform: skew(0.63deg);
              transform: skew(0.63deg); }
    40% {
      clip: rect(68px, 9999px, 39px, 0);
      -webkit-transform: skew(0.92deg);
              transform: skew(0.92deg); }
    43.33333% {
      clip: rect(48px, 9999px, 1px, 0);
      -webkit-transform: skew(0.52deg);
              transform: skew(0.52deg); }
    46.66667% {
      clip: rect(67px, 9999px, 13px, 0);
      -webkit-transform: skew(0.1deg);
              transform: skew(0.1deg); }
    50% {
      clip: rect(17px, 9999px, 100px, 0);
      -webkit-transform: skew(0.22deg);
              transform: skew(0.22deg); }
    53.33333% {
      clip: rect(40px, 9999px, 49px, 0);
      -webkit-transform: skew(0.98deg);
              transform: skew(0.98deg); }
    56.66667% {
      clip: rect(22px, 9999px, 45px, 0);
      -webkit-transform: skew(0.26deg);
              transform: skew(0.26deg); }
    60% {
      clip: rect(83px, 9999px, 51px, 0);
      -webkit-transform: skew(0.54deg);
              transform: skew(0.54deg); }
    63.33333% {
      clip: rect(38px, 9999px, 93px, 0);
      -webkit-transform: skew(0.39deg);
              transform: skew(0.39deg); }
    66.66667% {
      clip: rect(2px, 9999px, 49px, 0);
      -webkit-transform: skew(0.29deg);
              transform: skew(0.29deg); }
    70% {
      clip: rect(86px, 9999px, 95px, 0);
      -webkit-transform: skew(0.56deg);
              transform: skew(0.56deg); }
    73.33333% {
      clip: rect(52px, 9999px, 73px, 0);
      -webkit-transform: skew(0.47deg);
              transform: skew(0.47deg); }
    76.66667% {
      clip: rect(52px, 9999px, 9px, 0);
      -webkit-transform: skew(0.31deg);
              transform: skew(0.31deg); }
    80% {
      clip: rect(14px, 9999px, 82px, 0);
      -webkit-transform: skew(0.38deg);
              transform: skew(0.38deg); }
    83.33333% {
      clip: rect(76px, 9999px, 22px, 0);
      -webkit-transform: skew(0.31deg);
              transform: skew(0.31deg); }
    86.66667% {
      clip: rect(47px, 9999px, 45px, 0);
      -webkit-transform: skew(0.49deg);
              transform: skew(0.49deg); }
    90% {
      clip: rect(59px, 9999px, 17px, 0);
      -webkit-transform: skew(0.78deg);
              transform: skew(0.78deg); }
    93.33333% {
      clip: rect(44px, 9999px, 59px, 0);
      -webkit-transform: skew(0.82deg);
              transform: skew(0.82deg); }
    96.66667% {
      clip: rect(37px, 9999px, 31px, 0);
      -webkit-transform: skew(0.84deg);
              transform: skew(0.84deg); }
    100% {
      clip: rect(3px, 9999px, 87px, 0);
      -webkit-transform: skew(0.37deg);
              transform: skew(0.37deg); } }
  @keyframes name-anim {
    0% {
      clip: rect(100px, 9999px, 5px, 0);
      -webkit-transform: skew(0.79deg);
              transform: skew(0.79deg); }
    3.33333% {
      clip: rect(67px, 9999px, 94px, 0);
      -webkit-transform: skew(0.32deg);
              transform: skew(0.32deg); }
    6.66667% {
      clip: rect(47px, 9999px, 71px, 0);
      -webkit-transform: skew(0.33deg);
              transform: skew(0.33deg); }
    10% {
      clip: rect(62px, 9999px, 66px, 0);
      -webkit-transform: skew(0.17deg);
              transform: skew(0.17deg); }
    13.33333% {
      clip: rect(8px, 9999px, 41px, 0);
      -webkit-transform: skew(0.4deg);
              transform: skew(0.4deg); }
    16.66667% {
      clip: rect(48px, 9999px, 69px, 0);
      -webkit-transform: skew(0.87deg);
              transform: skew(0.87deg); }
    20% {
      clip: rect(40px, 9999px, 6px, 0);
      -webkit-transform: skew(0.58deg);
              transform: skew(0.58deg); }
    23.33333% {
      clip: rect(60px, 9999px, 9px, 0);
      -webkit-transform: skew(0.22deg);
              transform: skew(0.22deg); }
    26.66667% {
      clip: rect(70px, 9999px, 53px, 0);
      -webkit-transform: skew(0.79deg);
              transform: skew(0.79deg); }
    30% {
      clip: rect(41px, 9999px, 61px, 0);
      -webkit-transform: skew(0.32deg);
              transform: skew(0.32deg); }
    33.33333% {
      clip: rect(50px, 9999px, 54px, 0);
      -webkit-transform: skew(0.47deg);
              transform: skew(0.47deg); }
    36.66667% {
      clip: rect(25px, 9999px, 16px, 0);
      -webkit-transform: skew(0.63deg);
              transform: skew(0.63deg); }
    40% {
      clip: rect(68px, 9999px, 39px, 0);
      -webkit-transform: skew(0.92deg);
              transform: skew(0.92deg); }
    43.33333% {
      clip: rect(48px, 9999px, 1px, 0);
      -webkit-transform: skew(0.52deg);
              transform: skew(0.52deg); }
    46.66667% {
      clip: rect(67px, 9999px, 13px, 0);
      -webkit-transform: skew(0.1deg);
              transform: skew(0.1deg); }
    50% {
      clip: rect(17px, 9999px, 100px, 0);
      -webkit-transform: skew(0.22deg);
              transform: skew(0.22deg); }
    53.33333% {
      clip: rect(40px, 9999px, 49px, 0);
      -webkit-transform: skew(0.98deg);
              transform: skew(0.98deg); }
    56.66667% {
      clip: rect(22px, 9999px, 45px, 0);
      -webkit-transform: skew(0.26deg);
              transform: skew(0.26deg); }
    60% {
      clip: rect(83px, 9999px, 51px, 0);
      -webkit-transform: skew(0.54deg);
              transform: skew(0.54deg); }
    63.33333% {
      clip: rect(38px, 9999px, 93px, 0);
      -webkit-transform: skew(0.39deg);
              transform: skew(0.39deg); }
    66.66667% {
      clip: rect(2px, 9999px, 49px, 0);
      -webkit-transform: skew(0.29deg);
              transform: skew(0.29deg); }
    70% {
      clip: rect(86px, 9999px, 95px, 0);
      -webkit-transform: skew(0.56deg);
              transform: skew(0.56deg); }
    73.33333% {
      clip: rect(52px, 9999px, 73px, 0);
      -webkit-transform: skew(0.47deg);
              transform: skew(0.47deg); }
    76.66667% {
      clip: rect(52px, 9999px, 9px, 0);
      -webkit-transform: skew(0.31deg);
              transform: skew(0.31deg); }
    80% {
      clip: rect(14px, 9999px, 82px, 0);
      -webkit-transform: skew(0.38deg);
              transform: skew(0.38deg); }
    83.33333% {
      clip: rect(76px, 9999px, 22px, 0);
      -webkit-transform: skew(0.31deg);
              transform: skew(0.31deg); }
    86.66667% {
      clip: rect(47px, 9999px, 45px, 0);
      -webkit-transform: skew(0.49deg);
              transform: skew(0.49deg); }
    90% {
      clip: rect(59px, 9999px, 17px, 0);
      -webkit-transform: skew(0.78deg);
              transform: skew(0.78deg); }
    93.33333% {
      clip: rect(44px, 9999px, 59px, 0);
      -webkit-transform: skew(0.82deg);
              transform: skew(0.82deg); }
    96.66667% {
      clip: rect(37px, 9999px, 31px, 0);
      -webkit-transform: skew(0.84deg);
              transform: skew(0.84deg); }
    100% {
      clip: rect(3px, 9999px, 87px, 0);
      -webkit-transform: skew(0.37deg);
              transform: skew(0.37deg); } }
  @-webkit-keyframes name-anim2 {
    0% {
      clip: rect(24px, 9999px, 1px, 0);
      -webkit-transform: skew(0.43deg);
              transform: skew(0.43deg); }
    5% {
      clip: rect(32px, 9999px, 77px, 0);
      -webkit-transform: skew(0.77deg);
              transform: skew(0.77deg); }
    10% {
      clip: rect(13px, 9999px, 100px, 0);
      -webkit-transform: skew(0.65deg);
              transform: skew(0.65deg); }
    15% {
      clip: rect(71px, 9999px, 70px, 0);
      -webkit-transform: skew(0.89deg);
              transform: skew(0.89deg); }
    20% {
      clip: rect(1px, 9999px, 47px, 0);
      -webkit-transform: skew(0.16deg);
              transform: skew(0.16deg); }
    25% {
      clip: rect(56px, 9999px, 19px, 0);
      -webkit-transform: skew(0.56deg);
              transform: skew(0.56deg); }
    30% {
      clip: rect(91px, 9999px, 11px, 0);
      -webkit-transform: skew(0.75deg);
              transform: skew(0.75deg); }
    35% {
      clip: rect(18px, 9999px, 33px, 0);
      -webkit-transform: skew(0.2deg);
              transform: skew(0.2deg); }
    40% {
      clip: rect(64px, 9999px, 32px, 0);
      -webkit-transform: skew(0.43deg);
              transform: skew(0.43deg); }
    45% {
      clip: rect(93px, 9999px, 7px, 0);
      -webkit-transform: skew(0.1deg);
              transform: skew(0.1deg); }
    50% {
      clip: rect(49px, 9999px, 28px, 0);
      -webkit-transform: skew(0.93deg);
              transform: skew(0.93deg); }
    55% {
      clip: rect(11px, 9999px, 10px, 0);
      -webkit-transform: skew(0.48deg);
              transform: skew(0.48deg); }
    60% {
      clip: rect(12px, 9999px, 57px, 0);
      -webkit-transform: skew(0.32deg);
              transform: skew(0.32deg); }
    65% {
      clip: rect(30px, 9999px, 76px, 0);
      -webkit-transform: skew(0.43deg);
              transform: skew(0.43deg); }
    70% {
      clip: rect(36px, 9999px, 6px, 0);
      -webkit-transform: skew(0.05deg);
              transform: skew(0.05deg); }
    75% {
      clip: rect(1px, 9999px, 74px, 0);
      -webkit-transform: skew(0.09deg);
              transform: skew(0.09deg); }
    80% {
      clip: rect(39px, 9999px, 82px, 0);
      -webkit-transform: skew(0.7deg);
              transform: skew(0.7deg); }
    85% {
      clip: rect(55px, 9999px, 30px, 0);
      -webkit-transform: skew(0.18deg);
              transform: skew(0.18deg); }
    90% {
      clip: rect(91px, 9999px, 65px, 0);
      -webkit-transform: skew(0.45deg);
              transform: skew(0.45deg); }
    95% {
      clip: rect(96px, 9999px, 66px, 0);
      -webkit-transform: skew(0.58deg);
              transform: skew(0.58deg); }
    100% {
      clip: rect(70px, 9999px, 46px, 0);
      -webkit-transform: skew(0.6deg);
              transform: skew(0.6deg); } }
  @keyframes name-anim2 {
    0% {
      clip: rect(24px, 9999px, 1px, 0);
      -webkit-transform: skew(0.43deg);
              transform: skew(0.43deg); }
    5% {
      clip: rect(32px, 9999px, 77px, 0);
      -webkit-transform: skew(0.77deg);
              transform: skew(0.77deg); }
    10% {
      clip: rect(13px, 9999px, 100px, 0);
      -webkit-transform: skew(0.65deg);
              transform: skew(0.65deg); }
    15% {
      clip: rect(71px, 9999px, 70px, 0);
      -webkit-transform: skew(0.89deg);
              transform: skew(0.89deg); }
    20% {
      clip: rect(1px, 9999px, 47px, 0);
      -webkit-transform: skew(0.16deg);
              transform: skew(0.16deg); }
    25% {
      clip: rect(56px, 9999px, 19px, 0);
      -webkit-transform: skew(0.56deg);
              transform: skew(0.56deg); }
    30% {
      clip: rect(91px, 9999px, 11px, 0);
      -webkit-transform: skew(0.75deg);
              transform: skew(0.75deg); }
    35% {
      clip: rect(18px, 9999px, 33px, 0);
      -webkit-transform: skew(0.2deg);
              transform: skew(0.2deg); }
    40% {
      clip: rect(64px, 9999px, 32px, 0);
      -webkit-transform: skew(0.43deg);
              transform: skew(0.43deg); }
    45% {
      clip: rect(93px, 9999px, 7px, 0);
      -webkit-transform: skew(0.1deg);
              transform: skew(0.1deg); }
    50% {
      clip: rect(49px, 9999px, 28px, 0);
      -webkit-transform: skew(0.93deg);
              transform: skew(0.93deg); }
    55% {
      clip: rect(11px, 9999px, 10px, 0);
      -webkit-transform: skew(0.48deg);
              transform: skew(0.48deg); }
    60% {
      clip: rect(12px, 9999px, 57px, 0);
      -webkit-transform: skew(0.32deg);
              transform: skew(0.32deg); }
    65% {
      clip: rect(30px, 9999px, 76px, 0);
      -webkit-transform: skew(0.43deg);
              transform: skew(0.43deg); }
    70% {
      clip: rect(36px, 9999px, 6px, 0);
      -webkit-transform: skew(0.05deg);
              transform: skew(0.05deg); }
    75% {
      clip: rect(1px, 9999px, 74px, 0);
      -webkit-transform: skew(0.09deg);
              transform: skew(0.09deg); }
    80% {
      clip: rect(39px, 9999px, 82px, 0);
      -webkit-transform: skew(0.7deg);
              transform: skew(0.7deg); }
    85% {
      clip: rect(55px, 9999px, 30px, 0);
      -webkit-transform: skew(0.18deg);
              transform: skew(0.18deg); }
    90% {
      clip: rect(91px, 9999px, 65px, 0);
      -webkit-transform: skew(0.45deg);
              transform: skew(0.45deg); }
    95% {
      clip: rect(96px, 9999px, 66px, 0);
      -webkit-transform: skew(0.58deg);
              transform: skew(0.58deg); }
    100% {
      clip: rect(70px, 9999px, 46px, 0);
      -webkit-transform: skew(0.6deg);
              transform: skew(0.6deg); } }
  @-webkit-keyframes name-skew {
    0% {
      -webkit-transform: skew(9deg);
              transform: skew(9deg); }
    10% {
      -webkit-transform: skew(3deg);
              transform: skew(3deg); }
    20% {
      -webkit-transform: skew(4deg);
              transform: skew(4deg); }
    30% {
      -webkit-transform: skew(3deg);
              transform: skew(3deg); }
    40% {
      -webkit-transform: skew(2deg);
              transform: skew(2deg); }
    50% {
      -webkit-transform: skew(-2deg);
              transform: skew(-2deg); }
    60% {
      -webkit-transform: skew(6deg);
              transform: skew(6deg); }
    70% {
      -webkit-transform: skew(5deg);
              transform: skew(5deg); }
    80% {
      -webkit-transform: skew(-3deg);
              transform: skew(-3deg); }
    90% {
      -webkit-transform: skew(1deg);
              transform: skew(1deg); }
    100% {
      -webkit-transform: skew(-4deg);
              transform: skew(-4deg); } }
  @keyframes name-skew {
    0% {
      -webkit-transform: skew(9deg);
              transform: skew(9deg); }
    10% {
      -webkit-transform: skew(3deg);
              transform: skew(3deg); }
    20% {
      -webkit-transform: skew(4deg);
              transform: skew(4deg); }
    30% {
      -webkit-transform: skew(3deg);
              transform: skew(3deg); }
    40% {
      -webkit-transform: skew(2deg);
              transform: skew(2deg); }
    50% {
      -webkit-transform: skew(-2deg);
              transform: skew(-2deg); }
    60% {
      -webkit-transform: skew(6deg);
              transform: skew(6deg); }
    70% {
      -webkit-transform: skew(5deg);
              transform: skew(5deg); }
    80% {
      -webkit-transform: skew(-3deg);
              transform: skew(-3deg); }
    90% {
      -webkit-transform: skew(1deg);
              transform: skew(1deg); }
    100% {
      -webkit-transform: skew(-4deg);
              transform: skew(-4deg); } } }

