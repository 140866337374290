 html, body{
   padding: 0!important;
   margin: 0!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background: linear-gradient(#fffef6, rgb(186, 255, 174));*/
    background: linear-gradient(rgba(10, 10, 10, 0.91), rgba(0, 0, 0, 0.71)), repeating-linear-gradient(0, transparent, transparent 2px, rgba(0, 0, 0, 0.52) 2px, rgba(0, 0, 0, 0) 3px)
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }
