@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600&family=Zen+Tokyo+Zoo&display=swap');

/****************************************************************** HEADER ********************************************/

@media all {
    .header {
        justify-content: center;
        margin: 0 !important;
        padding: 0 !important;
        display: flex;
        height: 5rem;
        width: 100%;
        //color: #4c4c4c;
        //animation: name-skew 7s infinite alternate-reverse;
        //border-bottom: rgba(16, 17, 65, 0.36) 1px solid;
    }

    .header-nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        margin: 0 !important;
    }

    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0 0 0 !important;
        margin: 0 !important;
    }

    li {
        margin-left: 1rem;
        list-style: none;
    }
    a {
        display: inline-block;
        align-items: center;
        color: #b5b5b5;
        transition: color 400ms ease-out;
        list-style: none;
        line-height: 1;
    }
    a:hover {
        color: #918c90;
    }
    svg {
        fill: #dbdbdb;
    }

    a.router-link-exact-active {
        opacity: .5;
        cursor: default;
    }

    svg {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        fill: #682ae9;
        position: relative;
        top: 2px;
        transition: fill 400ms ease-out;
    }


    /**********************************************************************************************************************/
    @mixin nameCopy {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .name {
        margin-left: 5px;
        font-family: 'Dancing Script', cursive;
        position: relative;
        color: #b5b5b5;
        font-size: 2.5em;
        letter-spacing: .2em;
        animation: name-skew 1s infinite linear alternate-reverse;

        &::before {
            @include nameCopy;
            left: 2px;
            text-shadow: -3px 0 #ff3520, 2px 2px #101141;
            clip: rect(44px, 800px, 56px, 0);
            animation: name-anim 7s infinite linear alternate-reverse;
        }

        &::after {
            @include nameCopy;
            left: -2px;
            text-shadow: -2px 0 #0f1ba0, 2px 2px #ffe823;
            animation: name-anim2 4s infinite linear alternate-reverse;
        }
    }

    @keyframes name-anim {

        $steps: 30;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes name-anim2 {
        $steps: 20;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                clip: rect(random(100)+px, 9999px, random(100)+px, 0);
                transform: skew((random(100) / 100) + deg);
            }
        }
    }

    @keyframes name-skew {
        $steps: 10;
        @for $i from 0 through $steps {
            #{percentage($i*(1/$steps))} {
                transform: skew((random(15) - 5) + deg);
            }
        }
    }
}