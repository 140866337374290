/*h1 {*/
/*  font-size: 45px;*/
/*}*/
/*h2 {*/
/*  margin-top: 1.5em;*/
/*}*/
/*p {*/
/*  font-size: 16px;*/
/*}*/
/*a {*/
/*  border-bottom: 1px solid white;*/
/*  color: #0076ff;*/
/*  cursor: pointer;*/
/*  text-decoration: none;*/
/*  transition: all 0.2s ease;*/
/*}*/
/*a:hover {*/
/*  border-bottom: 1px solid #0076ff;*/
/*}*/
/*code,*/
/*pre {*/
/*  color: #d400ff;*/
/*  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,*/
/*    Bitstream Vera Sans Mono, Courier New, monospace, serif;*/
/*  font-size: 0.92em;*/
/*}*/
/*code:before,*/
/*code:after {*/
/*  content: '\`';*/
/*}*/

/*.name{*/
/*  visibility: inherit;*/
/*  opacity: 1;*/
/*  transform: matrix(1, 0, 0, 1, 0, 0);*/
/*  font-size: 1.2rem;*/
/*  font-weight: 700;*/
/*  color: #682ae9;*/
/*}*/


/******************************************* Centre ******************************************************************/
.orga{
  display: flex !important;
  flex-direction: column !important;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center;

}

/************************************************ bloc 1 **************************************************************/
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

.blocUn{
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #0a0a0a;
  background: linear-gradient(rgba(211, 211, 211, 0.85), rgba(181, 181, 181, 0.5));
  width: 100%;
  height: 80vh;
  border-bottom: #dbdbdb 1px solid;
}

.title{
  font-family: 'Indie Flower', cursive;
  font-weight: 800;
  font-size: 45px;
}

.title-bis{
  font-family: 'Indie Flower', cursive;
  font-weight: 600;
  font-size: 20px;
}

.dev{
  font-family: OCR A Std, monospace;
  font-weight: bold;
}

/********************************** Bloc Deux ***********************************************************************/
.blocDeux{
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  color: #4b4b4b;
  background: linear-gradient(rgba(181, 181, 181, 0.5), rgba(211, 211, 211, 0.85));
  width: 100%;
  height: 80vh;
  border-bottom: #dbdbdb 1px solid;
}
/***********************************Bloc Trois **************************************************************************/

.blocTrois {
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  color: #535353;
  background: linear-gradient(rgba(211, 211, 211, 0.85), rgba(181, 181, 181, 0.5));
  width: 100%;
  height: 80vh;
  border-bottom: #dbdbdb 1px solid;
}